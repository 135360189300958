<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-btn icon rounded exact :to="{name: 'IntroProfile'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="accent" rounded :to="{name: 'IntroFinished'}">next</v-btn>
		</v-app-bar>
		<v-container>
			<v-row>
				<v-col cols="12" class="text-center pt-5">
					<h4 class="text-h4 text-center">curated</h4>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" lg="10" xl="8">
					<r-curated></r-curated>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
export default {
	name: "Curated",
	metaInfo: {
		title: "Curated",
	},
}
</script>
